<template>
  <div class="shipments-page">
    <h2 class="mb-6">{{$t('Picking Task Lists')}}</h2>
    <div class="page-title p-4 flex gap-3">
      <div :class="$route.query.tab === tab ? 'bg-primary text-white' : ''" class="py-2 px-4 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
        v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab)">
        <p class="text-sm">{{$t(tab)}}</p>
      </div>
    </div>
      <shipblu-table
        pagination
        :data="dataShow"
        :tableLoader="tableLoader"
      >
      <template slot="thead">
        <shipblu-th>{{$t('ID')}}</shipblu-th>
        <shipblu-th>{{$t('Created on')}}</shipblu-th>
        <shipblu-th>{{$t('Type')}}</shipblu-th>
        <shipblu-th>{{$t('SKU')}}</shipblu-th>
        <shipblu-th>{{$t('QTY')}}</shipblu-th>
        <shipblu-th>{{$t('Picker')}}</shipblu-th>
        <shipblu-th v-if="activeTab === 1">{{$t('Packer')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>
      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].id">
            {{ data[indextr].id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].created">
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].type">
            {{$t(data[indextr].type)}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].sku_count">
            {{ data[indextr].sku_count }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].products_in_orders_quantity">
            {{ data[indextr].products_in_orders_quantity }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].type" v-if="activeTab === 0">
            {{ pickNameDic[data[indextr].agent] }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].type" v-if="activeTab === 1">
            {{pickNameDic[data[indextr].picking_agent] }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].type" v-if="activeTab === 1">
            {{packNameDic[data[indextr].agent] }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" class="whitespace-nowrap">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{$t(getStatusLabel(data[indextr]))}}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
  </div>
</template>

<script>
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'

export default {
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd
  },
  data () {
    return {
      pickingTaskList: [],
      packingTaskList: [],
      dataShow: [],
      common,
      activeTab: 0,
      tabs: ['Picking List', 'Packing List'],
      tabsDic: {'0': 'Picking List', '1': 'Packing List'},
      tabsNameDic: {'Picking List': 0, 'Packing List': 1},
      pickNameDic: {},
      packNameDic: {},
      fcPicking: [],
      tableLoader: false,
      fcPacking: []
    }
  },
  watch: {
    activeTab () {
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab]
        }
      }).catch(() => {})
      if (this.activeTab === 0) {
        this.dataShow = this.pickingTaskList
      } else {
        this.dataShow = this.packingTaskList
      }
    }
  },
  methods: {
    loadPickingTaskList () {
      this.tableLoader = true
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/picking-tasklists/', 'get', null, true,
        (response) => {
          this.pickingTaskList = response.data.results
          this.dataShow = this.pickingTaskList
          this.tableLoader = false
        }
      )
    },
    loadPackingTaskList () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/packing-tasklists/', 'get', null, true,
        (response) => {
          this.packingTaskList = response.data.results
        }
      )
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
    },
    loadPickingAgent () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/users/fc-picking-agent/', 'get', null, true,
        (response) => {
          this.fcPicking = response.data
          this.fcPicking = this.fcPicking.reverse()
          this.fcPicking.forEach(item  => {
            this.pickNameDic[item.user_id] = item.name
          })
          this.loadPickingTaskList()
          this.loadPackingTaskList()
        }
      )
    },
    loadPackingAgent () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/users/fc-packing-agent/', 'get', null, true,
        (response) => {
          this.fcPacking = response.data
          this.fcPacking = this.fcPacking.reverse()
          this.fcPacking.forEach(item  => {
            this.packNameDic[item.user_id] = item.name
          })
        }
      )
    }
  },
  created () {
    this.$router.push({
      query: {
        tab: this.tabsDic[this.activeTab]
      }
    }).catch(() => {})
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    this.loadPickingAgent()
    this.loadPackingAgent()
  }
}
</script>